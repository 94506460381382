import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import ServicesData from "../../content/services/index.json"
import {Container} from "react-bootstrap";
import SimpleBlock from "../../components/misc/simple-block";
import HeroPagesCenter from "../../components/heroes/hero-pages-center"

const ServicesIndexPage = ({ intl, location }) => (
  <Layout>
    <Seo lang={intl.locale} title={ServicesData[intl.locale].title} description={ServicesData[intl.locale].text} pathname={location.pathname} />
      <HeroPagesCenter
          theme={`primary`}
          content={ServicesData[intl.locale]}
      />
      <Container>
          {ServicesData[intl.locale].services.map((item, i) => {

              return (
                  <SimpleBlock key={i} content={item} />
              )

          })}
      </Container>
  </Layout>
)

export default injectIntl(ServicesIndexPage)
